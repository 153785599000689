import { Form as DSForm } from "@lifesg/react-design-system/form";
import { IDropdownItemTemplate } from "app/components/basic/Dropdown";
import { FormLabel } from "app/hooks/useForm/components/Form.Label";
import { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { IFormInputBaseProps } from "./Form.Input";

export interface IFormDropdownProps extends IFormInputBaseProps {
	/** A function that handles the dropdown input before selected */
	beforeSelectItem?: (...args: any[]) => void;
	/** A function that handles the dropdown input after selected */
	afterSelectItem?: (...args: any[]) => void;
}

const FormDropdown = ({
	form,
	field,
	title,
	subtitle,
	popupContent,
	disabled,
	className,
	hideErrorMessage,
	readOnly,
	beforeSelectItem,
	afterSelectItem,
	computerWidth,
	mobileWidth,
	tabletWidth,
	...otherProps
}: IFormDropdownProps): JSX.Element => {
	const [performAfterAction, setPerformAfterAction] = useState(false);

	useEffect(() => {
		if (performAfterAction) {
			if (afterSelectItem) {
				afterSelectItem();
			}
			if (otherProps.additionalValidations) {
				void otherProps.additionalValidations();
			}
			setPerformAfterAction(false);
		}
	}, [afterSelectItem, otherProps, performAfterAction]);

	const formField = form.getField(field);
	const key = formField ? formField.key : "";
	const errorMessageTestId = field + "-dropdown__error";

	let id = field + "__loading";

	if (!form.loading) {
		id = key + "-dropdown";
	}

	if (disabled) {
		id = key + "-dropdown--disabled";
	}

	const onBlurHandler = (): void => {
		if (!otherProps.disableBlurValidation) {
			const field = formField;
			form.validateFieldValue(field);
			void otherProps.additionalValidations?.();
		}
	};

	const errorMessage = formField && !hideErrorMessage ? form.getError(field) : "";
	const dropdownItems = formField
		? readOnly || disabled
			? [{ label: formField.value, value: formField.value }]
			: formField.dropdownItems
		: [];
	const isSubmitting = formField ? form.submittingFields[formField.key] : false;
	const value = formField ? formField.value : "";
	const valueDropdownItem = (dropdownItems as any)?.filter((curItem) =>
		curItem.value ? curItem.value === value : curItem.label === value,
	)[0];

	let fieldClassName = `form-field form-field-${key} ${className ?? ""} form-field__input--dropdown ${
		readOnly ? "readOnly" : ""
	} ${errorMessage ? "error" : ""} ${value ? "selected" : ""}`;
	if (disabled || (isSubmitting && !readOnly)) {
		fieldClassName += " form-field__input--dropdown--disabled";
	}

	if (!formField) {
		return <div />;
	}

	const onSelectItem = (item: IDropdownItemTemplate, value: string | IDropdownItemTemplate) => {
		if (beforeSelectItem) {
			beforeSelectItem();
		}

		form.updateFieldValue(key, value as string);

		if (afterSelectItem || otherProps.additionalValidations) {
			setPerformAfterAction(true);
		}
	};

	const inputWidth = computerWidth ?? 12;

	return (
		<Grid className={className?.includes("storybook") ? "form-storybook" : "form"}>
			<Grid.Row>
				<Grid.Column
					className="no-margin no-padding"
					mobile={mobileWidth ?? inputWidth}
					tablet={tabletWidth ?? inputWidth}
					computer={inputWidth}
				>
					<FormLabel
						id={id}
						title={title}
						subtitle={subtitle}
						popupContent={popupContent}
						fullWidthLabel={otherProps.fullWidthLabel}
						labelClassName={otherProps.labelClassName}
						formType={otherProps.formType}
					>
						<div className={fieldClassName}>
							<DSForm.Select
								onBlur={onBlurHandler}
								id={id}
								data-testid={id}
								name={key}
								errorMessage={errorMessage}
								selectedOption={valueDropdownItem}
								onSelectOption={onSelectItem}
								options={(dropdownItems as any) || []}
								listExtractor={(item: IDropdownItemTemplate) => item.label}
								valueExtractor={(item: IDropdownItemTemplate) => (item.value as string) || item.label}
								displayValueExtractor={(item: IDropdownItemTemplate) => item.label}
								optionsLoadState="success"
								data-error-testid={errorMessageTestId}
								disabled={disabled || isSubmitting}
								dropdownZIndex={100000}
								readOnly={readOnly}
								{...otherProps}
							/>
						</div>
					</FormLabel>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default FormDropdown;

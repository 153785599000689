import { ErrorDisplay } from "@lifesg/react-design-system/error-display";
import router from "next/router";
import { DescriptionText, ErrorContainer } from "./CustomErrorStyles";
import { useState, useEffect } from "react";

const CustomError = ({ errorData }): JSX.Element => {
	const [topHeight, setTopHeight] = useState(0);

	useEffect(() => {
		const mastheadElement = document.querySelector("sgds-masthead") as HTMLElement;
		const headerElement = document.getElementById("mylegacy_navbar") as HTMLElement;
		const footerElement = document.querySelector("footer") as HTMLElement;
		const viewportHeight = window.innerHeight;

		const contentHeight = headerElement.offsetHeight + mastheadElement.offsetHeight + footerElement.offsetHeight;
		const errorContainerHeight = (document.getElementById("error-container") as HTMLElement).offsetHeight;

		if (viewportHeight - contentHeight > errorContainerHeight) {
			setTopHeight((viewportHeight - contentHeight - errorContainerHeight) / 2);
		}
	}, []);

	return (
		<ErrorContainer id="error-container" topHeight={`${topHeight}px`}>
			{errorData.actionButton ? (
				<ErrorDisplay
					type={errorData?.type ?? "500"}
					title={errorData.title}
					description={<DescriptionText>{errorData.description}</DescriptionText>}
					actionButton={{
						children: `${errorData.actionButton.label}`,
						onClick: () => {
							router.push(errorData.actionButton.redirectPath ?? "/vault");
						},
					}}
				/>
			) : (
				<ErrorDisplay
					type={errorData?.type ?? "500"}
					title={errorData.title}
					description={<DescriptionText>{errorData.description}</DescriptionText>}
				/>
			)}
		</ErrorContainer>
	);
};

export default CustomError;

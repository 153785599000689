export const lowercaseAndHyphen = (rawString: string): string => {
	if (!rawString) {
		return "";
	}
	return rawString.toLowerCase().replace(/[^A-Z0-9]+/gi, "-");
};
/**
 * TODO
 * Update the function to ignore character after special characters
 * Example:
 *  consider only `Make an advance care plan` from `Make an advance care plan (ACP)`
 */
export const lowercaseAndHyphenSpecialChar = (rawString: string): string => {
	// ? and ' are special characters that are left alone (ie not converted into a '-'),
	// and removed in the 2nd regex call

	if (!rawString) {
		return "";
	}

	const newString = rawString.toLowerCase().replace(/[^A-Z0-9?']+/gi, "-");
	return newString.toLowerCase().replace(/[\?+\'+]/gi, "");
};

export const lowercaseAndSpace = (rawString: string): string => {
	const spacedString = rawString.replace(/([A-Z])/g, " $1").trim();
	return spacedString.toLowerCase();
};

export const addArticleToWord = (word: string): string => {
	const vowels: string[] = ["a", "e", "i", "o", "u"];
	if (vowels.includes(word.charAt(0))) {
		return `an ${word}`;
	}

	return `a ${word}`;
};

export const initialFromName = (name: string): string => {
	let initial = "";

	if (name && name.length) {
		name.split(" ").forEach((item: string, index: number) => {
			if (index < 2) {
				initial += item.charAt(0).toUpperCase();
			}
		});
	}
	const letters = /^[A-Za-z]+$/;
	if (!letters.test(initial)) {
		return "";
	}

	return initial;
};

export const shortenString = (input: string): string => {
	const maxCharCount = 149;
	let shortenedString = input.substring(0, maxCharCount);
	if (shortenedString.length < input.length) {
		shortenedString = shortenedString + "...";
	}
	return shortenedString;
};

export const capitalizeString = (input: string): string => {
	return input
		.split(" ")
		.map((w: string) => w.substring(0, 1).toUpperCase() + w.substring(1))
		.join(" ");
};

export const maskString = (input: string, maskRange: [number, number] = [1, 4], maskChar = "•") => {
	const [maskStart, maskEnd] = maskRange;
	const maskLength = input.length - (maskStart + maskEnd);

	if (maskLength <= 0) return input; // Return input as is if mask range is invalid

	const mask = maskChar.repeat(maskLength);
	return input.replace(new RegExp(`^(.{${maskStart}}).{${maskLength}}(.{${maskEnd}})$`), `$1${mask}$2`);
};

import { Text } from "@lifesg/react-design-system/text";
import styled from "styled-components";
import { MediaQuery } from "@lifesg/react-design-system/media";

interface ErrorContainerProps {
	topHeight: string;
}

export const ErrorContainer = styled.div<ErrorContainerProps>`
	margin-top: ${(props) => `${props.topHeight}`};
	padding: 2rem 1rem;

	img {
		${MediaQuery.MaxWidth.tablet} {
			height: 10.5rem;
		}
		height: 17.5rem;
		width: auto;
	}
`;

export const DescriptionText = styled(Text.Body)`
	margin-top: 0;
	p {
		margin: 0;
		font-weight: 600;
	}
`;

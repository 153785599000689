import { IsEmail, IsNotEmpty } from "class-validator";
import { HTTP_VERBS } from "../../enums";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v3.1/email";

export class Body {
	@IsEmail()
	@IsNotEmpty()
	email!: string;
}

export class Response {}

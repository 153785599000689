import * as contracts from "app/common/api/user/routes";
import API from "../client.contract";
import { ServiceType } from "app/modules/common/AllowAccess/constants";

const updateUserNotificationPreference = async (newPreference: contracts.putUserSettings.Body): Promise<void> => {
	await API.callV2<contracts.putUserSettings.Response>(contracts.putUserSettings, { body: newPreference });
};

const fetchUser = async (): Promise<contracts.getUser.Response> => {
	return await API.callV2<contracts.getUser.Response>(contracts.getUser);
};

const updateUserWithMyInfo = async (hasAgreed?: boolean): Promise<contracts.patchUserWithMyInfo.Response> => {
	const b = new contracts.patchUserWithMyInfo.Body();
	b.hasAgreed = hasAgreed;

	return await API.callV2<contracts.patchUserWithMyInfo.Response>(contracts.patchUserWithMyInfo, { body: b });
};

const refreshSession = async (): Promise<void> => {
	await API.callV2<contracts.getRefreshSession.Response>(contracts.getRefreshSession);
};

const getMyInfo = async (preventRedirect = false): Promise<contracts.getMyInfo.Response> => {
	return await API.callV2<contracts.getMyInfo.Response>(contracts.getMyInfo, {
		body: {
			preventRedirect,
		},
	});
};

const allowPermissions = async (serviceType: ServiceType): Promise<void> => {
	switch (serviceType) {
		case ServiceType.ACP:
			await API.callV2<contracts.allowAcpPermissions.Response>(contracts.allowAcpPermissions);
			break;
		case ServiceType.NOD:
			await API.callV2<contracts.allowNodPermissions.Response>(contracts.allowNodPermissions);
			break;
		case ServiceType.LPA:
			await API.callV2<contracts.allowOpgoPermissions.Response>(contracts.allowOpgoPermissions);
			break;
		default:
			break;
	}
};

const UserAPI = {
	fetchUser,
	updateUserNotificationPreference,
	refreshSession,
	updateUserWithMyInfo,
	getMyInfo,
	allowPermissions,
};

export default UserAPI;

import NotificationAPI from "app/api/notification";
import metadata from "app/assets/files/metadata.json";
import "app/assets/styles/main.scss";
import LanguageSelector from "app/components/basic/LanguageSelector";
import ErrorBoundary from "app/components/composites/ErrorBoundary";
import SessionModal from "app/components/composites/SessionModal/SessionModal";
import determineSessionStatus, {
	SessionStatusType,
} from "app/components/composites/SessionModal/determineSessionStatus";
import Maintenance from "app/components/hoc/Maintenance";
import Onboarding from "app/components/hoc/OnboardingScreen";
import Footer from "app/components/page/Footer";
import Navbar from "app/components/page/Navbar";
import NavbarGrey from "app/components/page/Navbar/Navbar.Grey";
import NavbarLoading from "app/components/page/Navbar/Navbar.Loading";
import TopBanner from "app/components/page/Navbar/TopBanner";
import VaultIntroPage from "app/components/templates/VaultPage/VaultIntroPage";
import { pagesWithoutScrollToTop } from "app/constants/pagesWithoutScrollToTop";
import { ErrorHandlerProvider } from "app/hooks/useErrorHandler";
import { ContextProvider } from "app/modules/common/ContextProvider";
import { getCookie } from "app/utils/cookies";
import { getEnvVar } from "app/utils/environmentVariable";
import { LoginContext } from "app/utils/login";
import { allowedDomains, scrollToAnchor } from "app/utils/url";
import App, { AppProps } from "next/app";
import Head from "next/head";

interface IState {
	loaded: boolean;
	unreadMessageCount: number;
	isLoggedIn: boolean;
	mainCssUrl: string;
}

const initialiseDataLayer = () => {
	if (typeof window !== "undefined" && !window["dataLayer"]) {
		window["dataLayer"] = [];
	}
};

const firePageViewEvent = (url) => {
	initialiseDataLayer();
	window["dataLayer"].push({
		event: "page_view",
		page_path: url,
	});
};

class MyApp extends App<AppProps> {
	public state: IState = {
		loaded: false,
		unreadMessageCount: 0,
		isLoggedIn: false,
		mainCssUrl: "https://assets.life.gov.sg/react-design-system/future/css/main.css",
	};

	public onRouteChangeComplete = (url: string): void => {
		if (!pagesWithoutScrollToTop.includes(url)) {
			window.scrollTo(0, 0);
		}
		void this.checkForNotifications();

		// Track pageview on route change
		firePageViewEvent(url);
	};

	public checkForNotifications = async (): Promise<void> => {
		// check for notifications when user is logged in
		const expiry = getCookie("expiry");
		// will return either timeout, inactive or active
		const sessionStatus = determineSessionStatus(expiry);

		if (sessionStatus === SessionStatusType.active) {
			const { count } = await NotificationAPI.getUnreadNotifications();
			if (count !== this.state.unreadMessageCount) {
				this.setState({ unreadMessageCount: count });
			}
			this.setState({ isLoggedIn: true, loaded: true });
		} else {
			this.setState({ isLoggedIn: false, loaded: true });
		}
	};

	private storePathValue = (url: string) => {
		const storage = globalThis?.sessionStorage;
		if (url !== storage?.getItem("prevPath")) {
			storage?.setItem("prevPath", storage?.getItem("currentPath") ?? "");
			storage?.setItem("currentPath", url);
		}
	};

	public componentDidMount = (): void => {
		const { router } = this.props;
		// Handle redirection
		if (router.asPath.includes("/vault/?redirect=")) {
			const redirectUrl = router.asPath.replace("/vault/?redirect=", "");
			if (allowedDomains(redirectUrl)) {
				router.replace(redirectUrl);
			}
		}

		this.storePathValue(this.props.router.asPath);
		this.props.router.events.on("routeChangeStart", this.storePathValue);
		this.props.router.events.on("routeChangeComplete", this.onRouteChangeComplete);

		const appAssetUrl = getEnvVar("APP_ASSET_URL");
		if (!this.state.mainCssUrl.includes(appAssetUrl)) {
			const newMainCssUrl =
				appAssetUrl === "https://assets.life.gov.sg"
					? "https://assets.life.gov.sg/react-design-system/future/css/main.css"
					: "https://nprod.assets.lifesg.io/react-design-system/css/main.css";
			this.setState({ mainCssUrl: newMainCssUrl });
		}

		void this.checkForNotifications();
		scrollToAnchor();
	};

	public componentWillUnmount = (): void => {
		this.props.router.events.off("routeChangeStart", this.storePathValue);
		this.props.router.events.off("routeChangeComplete", this.onRouteChangeComplete);
	};

	public render = (): JSX.Element => {
		/* eslint-disable @typescript-eslint/no-unsafe-assignment */
		const { Component, pageProps, router } = this.props;

		const isVaultPage = (pathname: string): boolean => {
			return /^\/vault.*/.test(pathname);
		};
		const { opengraphTitle = undefined, metaDescription = undefined } =
			(isVaultPage(router.pathname) ? metadata["/vault"] : metadata[router.pathname]) || {};

		const opengraphImage = `${process.env.PROD_ASSET_URL}/mylegacy/opengraph-white.png`;

		return (
			<ContextProvider>
				<SessionModal />
				{this.state.loaded ? (
					<LoginContext.Provider value={this.state.isLoggedIn}>
						<Onboarding>
							<Head>
								<link rel="stylesheet" type="text/css" href={this.state.mainCssUrl} />
							</Head>
							<div>
								<Navbar
									isLoggedIn={this.state.isLoggedIn}
									notifications={this.state.unreadMessageCount}
								/>
								<TopBanner />
								<div className="body-content">
									<Maintenance>
										<ErrorBoundary>
											<ErrorHandlerProvider>
												<LanguageSelector className="mt32" id="language-selector-top" />
												{!this.state.isLoggedIn && router.pathname.includes("vault") ? (
													// all the vault pages should only be accessed if the user is logged in
													<VaultIntroPage redirectURL={router.asPath} />
												) : (
													<Component {...pageProps} />
												)}

												<LanguageSelector
													className="mb104"
													id="language-selector-bottom"
													hideWhenTranslationUnavailable={true}
												/>
											</ErrorHandlerProvider>
										</ErrorBoundary>
									</Maintenance>
								</div>
							</div>
							<Footer />
						</Onboarding>
					</LoginContext.Provider>
				) : (
					<>
						<NavbarGrey />
						<NavbarLoading />
						<TopBanner />
						<Head>
							{/* Read about decision at bottom of this file */}
							<meta property="og:title" content={opengraphTitle || "My Legacy"} />
							{metaDescription && <meta property="og:description" content={metaDescription} />}
							<meta property="og:image" content={opengraphImage} />
							<meta property="og:image:width" content="1200" />
							<meta property="og:image:height" content="630" />
							<meta property="og:image:alt" content="My Legacy logo" />
							<meta property="og:type" content="website" />
						</Head>
					</>
				)}
			</ContextProvider>
		);
	};
}

export default MyApp;

// decision on opengraph:
// og:url: purpose of having the url is so that the social media can scape another url that is different from the one being shared
// we currently do not have a use case for this, and it is technically challenging to set different urls for different environment
// hence this property is not put in.

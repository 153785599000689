import { HTTP_VERBS } from "../../enums";
import { AcpFormDO } from "../domainObjects/v3/acpForm";
import { IsString } from "class-validator";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v3/acp/nhs/acp-form";

export class Body {
	@IsString()
	acpId!: string;
}

export class Response extends AcpFormDO {}

import { ZonedDateTime } from "@js-joda/core";
import { ClassTransformers, IsArray } from "@wog/mol-lib-api-contract/utils";
import { Transform } from "class-transformer";
import { IsEnum, IsNumber, IsString } from "class-validator";
import AcpNhs from "server/models/advanceCarePlan/acpNhs";
import { AcpFormV3Status } from "../../enums";

export class AcpFormDO {
	@IsEnum(AcpFormV3Status)
	status!: AcpFormV3Status;

	@IsString()
	draftId!: string;

	@IsNumber()
	currentStep!: number;

	@IsNumber()
	latestStep!: number;

	@Transform(ClassTransformers.ZonedDateTimeClassTransformer)
	createdAt!: string;

	formContext?: {
		[key: string]: object;
	};

	@Transform(ClassTransformers.ZonedDateTimeClassTransformer)
	expiresAfter!: ZonedDateTime;

	@IsArray()
	spokespersons?: AcpNhs[];
}

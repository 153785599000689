import ConfigAPI from "app/api/config";
import DeathCertAPI from "app/api/deathCert";
import UserAPI from "app/api/user";
import { FrontEndEnvKeys } from "app/common/api/config";
import { IdentificationType } from "app/common/api/deathCert/enums";
import { getMyInfo, getUser as getUserApi } from "app/common/api/user/routes";
import { EOLErrorCodes } from "app/common/errors";
import Header from "app/components/basic/Header";
import { useGlobalLoadingState } from "app/components/basic/LinearProgressIndicator/GlobalLoadingStateProvider";
import determineSessionStatus, {
	SessionStatusType,
} from "app/components/composites/SessionModal/determineSessionStatus";
import Segment from "app/components/page/Segment";
import { DeathCertFormContext, ErrorType } from "app/components/templates/DeathCert/Forms/DeathCertFormContext";
import {
	DeceasedDetailsForm,
	RequestorDetailsForm,
	STEPS_TITLE,
	isStillBirth,
	validateAtLeastOneContactDetail,
	validateIdentificationIfNric,
} from "app/components/templates/DeathCert/Forms/formUtils";
import {
	FormKeys,
	downloadDeathCertFormSchema,
	downloadStillbirthFormSchema,
} from "app/components/templates/DeathCert/Forms/schemas";
import PublicPage from "app/components/templates/PublicPage";
import ErrorMessage from "app/components/widgets/ErrorMessage";
import { useErrorHandler } from "app/hooks/useErrorHandler";
import { IForm, useForm } from "app/hooks/useForm";
import { useShowErrorPage } from "app/hooks/useShowErrorPage";
import { getCookie } from "app/utils/cookies";
import _ from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Grid } from "semantic-ui-react";
import { DeathCertStepper } from "./DeathCertStepper";
import { FormButtons } from "./FormButtons";

const NRIC_REGEX = /^[ST]\d{7}[A-Z]/;

const DeathCertForm = (): JSX.Element => {
	const [, setLoading] = useGlobalLoadingState();
	const [user, setUser] = useState<getUserApi.Response | null>();
	const [myInfo, setMyInfo] = useState<getMyInfo.Response>();
	const addErrorCount = useShowErrorPage();
	const [myLegacyPublicKey, setMyLegacyPublicKey] = useState("");

	const [decIdSameAsAbove, setDecIdSameAsAbove] = useState(false);
	const { sendError } = useErrorHandler();
	const [shouldShowError, setShouldShowError] = useState<ErrorType>(null);

	const context = useContext(DeathCertFormContext);
	const { formResponses, goNext, goBack } = context;
	const _isStillBirth = useMemo(() => isStillBirth(context), [context]);

	const notFoundErrorBoxRef = useRef<HTMLDivElement>(null);

	const { form }: IForm = useForm();
	const {
		setupFormFields,
		ignoreChangesMade,
		forceWarnIfExit,
		updateFieldError,
		getValue,
		updateFieldValue,
		getFields,
	} = form;
	const router = useRouter();

	const expiry = getCookie("expiry");
	const sessionStatus = determineSessionStatus(expiry);

	const initUserData = useCallback(async () => {
		// try to load existing user info
		if (sessionStatus === SessionStatusType.active) {
			setLoading(true);
			try {
				const { email, phoneNumber } = formResponses;
				// should fetch and update only at form start where email and phone will be absent
				if (!email && !phoneNumber) {
					const _user: getUserApi.Response = await UserAPI.fetchUser();
					// in case the user is missing info I will try to fetch it from MyInfo
					if (!_user.phone || !_user.email) {
						const info: getMyInfo.Response = await UserAPI.getMyInfo();
						setMyInfo(info);
					}
					setUser(_user);
				} else {
					setUser({
						nric: formResponses.identificationNumber,
						name: formResponses.name,
						email: formResponses.email,
						phone: formResponses.phoneNumber,
					} as getUserApi.Response);
				}
				setLoading(false);
			} catch (e: any) {
				setLoading(false);
				sendError(e);
			}
		} else {
			setUser(null);
		}
	}, [sessionStatus, sendError, setLoading, formResponses]);

	useEffect(() => void initUserData(), [initUserData]);

	useEffect(() => {
		const setupForm = () => {
			const initialData = formResponses;
			// get information from logged in user, if any
			if (user) {
				_.merge(initialData, {
					[FormKeys.IDENTIFICATION_NUMBER]: user.nric,
					[FormKeys.NAME]: user.name,
					[FormKeys.EMAIL]: user.email ?? myInfo?.email,
					[FormKeys.PHONE]: user.phone ?? myInfo?.phone,
					[FormKeys.IDENTIFICATION_TYPE]: NRIC_REGEX.test(user.nric)
						? IdentificationType.NRIC
						: IdentificationType.FIN,
				});
			}

			setupFormFields(_isStillBirth ? downloadStillbirthFormSchema : downloadDeathCertFormSchema, initialData);
		};
		user !== undefined && setupForm();
	}, [user, myInfo, setupFormFields, formResponses, _isStillBirth]);

	useEffect(() => {
		forceWarnIfExit(true);
		ignoreChangesMade();
	}, [forceWarnIfExit, ignoreChangesMade]);

	const fetchMyLegacyPublicKey = useCallback(async (): Promise<void> => {
		const configData = await ConfigAPI.getConfigData();
		const env = configData.env as Record<FrontEndEnvKeys, string>;
		setMyLegacyPublicKey(env.MY_LEGACY_PUBLIC_KEY);
	}, []);

	useEffect(() => void fetchMyLegacyPublicKey(), [fetchMyLegacyPublicKey]);

	const onIdNumberChanged = useCallback(async () => {
		if (decIdSameAsAbove) {
			const idNumber = getValue(FormKeys.IDENTIFICATION_NUMBER) as string;
			updateFieldValue(FormKeys.DEC_IDENTIFICATION_NUMBER, idNumber);
		}
	}, [decIdSameAsAbove, getValue, updateFieldValue]);

	const onSetSameAsAbove = useCallback((sameAsAbove: boolean) => {
		setDecIdSameAsAbove(sameAsAbove);
	}, []);

	const validateDeathRecord = useCallback(async () => {
		const certificateNumber = getValue(FormKeys.DEC_CERT_NO);
		const type = formResponses[FormKeys.CERT_TYPE];
		const identificationNumber = getValue(FormKeys.DEC_IDENTIFICATION_NUMBER);
		const dateOfDeath = getValue(FormKeys.DEC_DEATH_DATE);
		const key = myLegacyPublicKey;

		try {
			const deathRecord = await DeathCertAPI.getDeathRecord({
				certificateNumber,
				type,
				identificationNumber,
				dateOfDeath,
				key,
			});
			setShouldShowError(null);
			const isExpired = deathRecord.expiresAfter?.startOf("day").isBefore(moment());
			if (isExpired) {
				await router.replace(`${router.asPath}expired/${formResponses[FormKeys.CERT_TYPE]}`);
				return false;
			}
		} catch (err: any) {
			if (err.errorMessage?.includes(EOLErrorCodes.DeathRecordNotFoundError)) {
				setShouldShowError("NotFound");
				notFoundErrorBoxRef.current?.scrollIntoView();
			} else {
				setShouldShowError("ApiError");
				addErrorCount();
				window.scrollTo(0, 0);
			}
			return false;
		}
		return true;
	}, [addErrorCount, formResponses, getValue, myLegacyPublicKey, router]);

	const validateAdditionalConditions = useCallback(async (): Promise<boolean> => {
		const validationResult = _.concat(validateAtLeastOneContactDetail(form), validateIdentificationIfNric(form));
		if (validationResult?.length) {
			for (const { key, error } of validationResult) {
				updateFieldError(key, error);
			}
			return false;
		}
		// check the death record is existing or is expired
		if (form.validateForm() && !(await validateDeathRecord())) {
			return false;
		}
		return true;
	}, [form, updateFieldError, validateDeathRecord]);

	const nextAction = useCallback(() => {
		// Proceed to next page
		const data = getFields();
		goNext(data);
	}, [getFields, goNext]);

	const backAction = useCallback(() => {
		const data = getFields();
		goBack(data);
	}, [getFields, goBack]);

	const pageTitle = _isStillBirth ? "Download stillbirth certificate" : "Download death certificate";

	return (
		<PublicPage title={pageTitle}>
			<Segment paddingBottom={104}>
				{shouldShowError === "ApiError" && (
					<ErrorMessage className="death-cert-form__error-message" type="error" id={"test"}>
						There was a problem retrieving the death certificate. Please try again.
					</ErrorMessage>
				)}
				<Header title={pageTitle} />
				<Grid className="death-cert-form">
					<Grid.Row columns={12}>
						<DeathCertStepper steps={STEPS_TITLE} currentStep={0} />
						<Grid.Column className="no-margin" mobile={12} tablet={12} computer={8}>
							<RequestorDetailsForm
								form={form}
								isUserLoggedIn={!!user}
								onIdNumberChanged={onIdNumberChanged}
							/>
							<DeceasedDetailsForm
								form={form}
								sameAsAbove={decIdSameAsAbove}
								onSetSameAsAbove={onSetSameAsAbove}
								shouldDisplayRecordNotFoundMsg={shouldShowError === "NotFound"}
								ref={notFoundErrorBoxRef}
							/>
							<FormButtons
								form={form}
								isEditingForm={context.isEditingForm}
								backAction={backAction}
								nextAction={_.debounce(nextAction, 500, { leading: true })}
								additionalValidations={validateAdditionalConditions}
							/>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</PublicPage>
	);
};

export default DeathCertForm;

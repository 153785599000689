// to be updated to new url
export const AcpBaseUrl = "/find-a-service/acp";

export const LEGAL_AGE = 21;

export enum AcpFormStep {
	PERSONAL_DETAILS = "step1",
	HEALTH_CONCERN = "step2a",
	PERSONAL_EXPERIENCES = "step2b",
	THINGS_YOU_ENJOY = "step3a",
	SOURCES_OF_SUPPORT = "step3b",
	FEARS = "step4",
	GOALS_OF_CARE = "step5",
	CARE_PREFERENCES = "step6",
	NHS = "step7a",
	NHS_ADDITIONAL_REQUESTS = "step7b",
	REVIEW = "step8",
}

export const AcpUrl = {
	landingPage: AcpBaseUrl,
	prepareACP: AcpBaseUrl,
	under21: "/user-under-21-years-old",
	alreadyResponded: "/already-responded",
	suitability: AcpBaseUrl + "/suitability",
	userUnder21Years: AcpBaseUrl + "/user-under-21-years-old",
	termsAndConditions: AcpBaseUrl + "/terms-and-conditions/",
	personalDetails: AcpBaseUrl + "/" + AcpFormStep.PERSONAL_DETAILS,
	healthConcerns: AcpBaseUrl + "/" + AcpFormStep.HEALTH_CONCERN,
	personalExperience: AcpBaseUrl + "/" + AcpFormStep.PERSONAL_EXPERIENCES,
	thingsYouEnjoy: AcpBaseUrl + "/" + AcpFormStep.THINGS_YOU_ENJOY,
	sourcesOfSupport: AcpBaseUrl + "/" + AcpFormStep.SOURCES_OF_SUPPORT,
	fears: AcpBaseUrl + "/" + AcpFormStep.FEARS,
	goalsOfCare: AcpBaseUrl + "/" + AcpFormStep.GOALS_OF_CARE,
	carePreferences: AcpBaseUrl + "/" + AcpFormStep.CARE_PREFERENCES,
	nhs: AcpBaseUrl + "/" + AcpFormStep.NHS,
	nhsAdditionalRequest: AcpBaseUrl + "/" + AcpFormStep.NHS_ADDITIONAL_REQUESTS,
	review: AcpBaseUrl + "/" + AcpFormStep.REVIEW,
	nextSteps: AcpBaseUrl + "/next-steps",
	allowAccess: AcpBaseUrl + "/allow-access",
	invalidDraftFoundPage: AcpBaseUrl + "/invalid-acp-found",
	existingRegisteredAcpFoundPage: AcpBaseUrl + "/registered-acp-found",
	viewAcpDraftPage: AcpBaseUrl + "/view-acp-draft",
	suitabilityCheckPage: AcpBaseUrl + "/check-suitability",
	existingACPApplicationPage: AcpBaseUrl + "/existing-application",
	viewApplicationPage: AcpBaseUrl + "/view-application",
	nhsResponse: AcpBaseUrl + "/nhs-response",
	editNhsRenomination: AcpBaseUrl + "/edit-nhs-renomination",
};

type AcpMetadata = { title: string; subtitle: string };

const baseAcpPageMetaData: Record<AcpFormStep, AcpMetadata> = {
	[AcpFormStep.PERSONAL_DETAILS]: {
		title: "Personal details",
		subtitle: "These details are from your Myinfo. Check if they're correct.",
	},
	[AcpFormStep.HEALTH_CONCERN]: {
		title: "Health concerns",
		subtitle: "Indicate any concerns you have about your health",
	},
	[AcpFormStep.PERSONAL_EXPERIENCES]: {
		title: "Personal experiences",
		subtitle: "Reflect on your experiences with medical situations",
	},
	[AcpFormStep.THINGS_YOU_ENJOY]: {
		title: "Things you enjoy in life",
		subtitle: "Share the things that matter the most to you in life",
	},
	[AcpFormStep.SOURCES_OF_SUPPORT]: {
		title: "Sources of support",
		subtitle: "Share who or what helps you in times of challenges",
	},
	[AcpFormStep.FEARS]: {
		title: "Fears or worries",
		subtitle: "Share any fears or worries you have about medical care",
	},
	[AcpFormStep.GOALS_OF_CARE]: {
		title: "Goals of medical care",
		subtitle: "Explore factors that affect your choice of medical care and decide on what suits you best",
	},
	[AcpFormStep.CARE_PREFERENCES]: {
		title: "Care preferences",
		subtitle: "Share any healthcare needs you want your healthcare team to prioritise ",
	},
	[AcpFormStep.NHS]: {
		title: "Nominated Healthcare Spokesperson",
		subtitle: "Choose someone you trust to convey your care preferences when you are unable to",
	},
	[AcpFormStep.NHS_ADDITIONAL_REQUESTS]: {
		title: "Additional requests",
		subtitle: "Share any additional requests you may have",
	},
	[AcpFormStep.REVIEW]: {
		title: "Review",
		subtitle: "Make sure all the information you provided accurately represents your preferences.",
	},
};

export const AcpPageMetaData = { ...baseAcpPageMetaData };

export const AcpPageMetaDataV2: Record<AcpFormStep, AcpMetadata> = {
	...baseAcpPageMetaData,
	[AcpFormStep.HEALTH_CONCERN]: {
		title: "Health information",
		subtitle: "Share your current state of health and any concerns you may have",
	},
	[AcpFormStep.PERSONAL_EXPERIENCES]: {
		title: "Experiences with medical care",
		subtitle: "Reflect on experiences that shape how you feel about medical treatments",
	},
};

export const AcpPreFormMetaData = {
	existingAcpApplicationPage: {
		heading: "Existing ACP application found",
		cardDescription: "Advance Care Plan",
		detailsCardDescription:
			"Your Nominated Healthcare Spokespersons (NHS) have been notified via SMS and email, and must respond to their appointment by logging in to MyLegacy@LifeSG.",
		pendingNHSRenomination:
			"Your Nominated Healthcare Spokesperson (NHS) rejected their appointment or is not eligible to be an NHS. Update your NHS nomination before your application expires.",
	},
	invalidDraftPage: {
		heading: "Invalid ACP draft found",
		title: "Advance Care Plan",
		description: [
			"This service is for those who have not made an ACP with a facilitator before.",
			"If you wish to make changes to your ACP, contact an ACP facilitator.",
		],
		cardDescription:
			"This draft is no longer valid as you have registered an Advance Care Plan (ACP) with a facilitator. Delete this draft to view your registered ACP.",
		detailsCardDescription:
			"This draft is no longer valid as you have registered an Advance Care Plan (ACP) with a facilitator. Delete this draft to view your registered ACP.",
	},
	registeredPage: {
		heading: "Existing registered ACP found",
		title: "Advance Care Plan",
		description: [
			"This service is for those who have not made an ACP with a facilitator before.",
			"If you wish to make changes to your ACP, contact an ACP facilitator. Otherwise, you can visit My Legacy vault to share your latest ACP with your Trusted Persons if you have not already done so.",
		],
	},
	registeredPageV2: {
		heading: "Existing registered ACP found",
		title: "Advance Care Plan",
		description1: [
			"If you wish to make changes to your ACP, contact an ACP facilitator. We recommend discussing your ACP with your current healthcare team or an ACP facilitator if you have any existing chronic condition or serious illness.",
			"Otherwise, you can visit My Legacy vault to share your latest ACP with your Trusted Persons if you have not already done so.",
		],
		description2: [
			"If you wish to make changes to your ACP, start a new one or contact an ACP facilitator to help you. ",
			"Some organisations may charge a nominal fee for doing an ACP. Kindly check in with the respective organisations on the fees.",
			"Your current ACP will be replaced once your new ACP has been successfully registered. ",
		],
	},
	suitabilityCheck: {
		heading: "Check suitability",
		title: "Before you start, check if you are suitable to start Advance Care Planning (ACP) using this online service.",
		description:
			"You must be generally healthy to make an Advance Care Plan online. If you have any existing chronic condition or serious illness, we recommend discussing your options with your current healthcare team or an ACP facilitator rather than using this service.",
		chronicDiseases: {
			title: "Chronic conditions include:",
			diseases: [
				"Allergic Rhinitis",
				"Anxiety",
				"Asthma",
				"Benign Prostatic Hyperplasia (BPH)",
				"Bipolar Disorder",
				"Chronic Hepatitis B",
				"Chronic Kidney Disease (Nephritis/Nephrosis)",
				"Chronic Obstructive Pulmonary Disease (COPD)",
				"Dementia",
				"Diabetes Mellitus and Pre-diabetes",
				"Epilepsy",
				"Gout",
				"Hypertension",
				"Ischaemic Heart Disease (IHD)",
				"Lipid Disorders",
				"Major Depression",
				"Osteoarthritis",
				"Osteoporosis",
				"Parkinson's Disease",
				"Psoriasis",
				"Rheumatoid Arthritis",
				"Schizophrenia",
				"Stroke ",
			],
			info: "List is correct as at 4 Sep 2024, according to Ministry of Health",
		},
		chronicDiseasesV2: {
			diseases: [
				"Chronic Kidney Disease",
				"Diabetes",
				"Ischemic Heart Disease (IHD)",
				"Parkinson's Disease",
				"Stroke ",
			],
		},
		seriousIllnesses: {
			title: "Serious illnesses include:",
			diseases: [
				"Cancer",
				"Chronic neurological diseases",
				"Chronic Obstructive Pulmonary Disease (COPD) and End-stage Lung Disease",
				"Dementia",
				"End-Stage Renal Failure (ESRF)",
				"Heart Failure",
				"Severe Liver Disease",
			],
		},
		seriousIllnessesV2: {
			diseases: [
				"Cancer",
				"Chronic neurological diseases",
				"Chronic Obstructive Pulmonary Disease (COPD) and End-stage Lung Disease",
				"Dementia",
				"End-Stage Renal Failure (ESRF)",
				"Heart Failure",
				"Severe Liver Disease",
			],
		},
		options: {
			title: "Do you have any existing chronic condition or serious illness?",
			yes: {
				description: [
					"As you have an existing chronic condition or serious illness, we recommend discussing your options with your current healthcare team or an ACP facilitator rather than using this service.",
				],
			},
			no: {
				description: ["By proceeding with this service, you are confirming that:"],
				confirmingList: [
					"you do not have any chronic condition or serious illness",
					"you are responsible for answering your health declarations correctly",
				],
			},
			notSure: {
				description: [
					"If you are unsure of your health condition, we recommend discussing your options with your current healthcare team or an ACP facilitator rather than using this service.",
				],
			},
		},
	},
	suitabilityCheckV2: {
		heading: "Check suitability",
		title: "Before you start, check if you are suitable to start Advance Care Planning (ACP) using this online service.",
		options: [
			{
				title: "Do you have any existing serious illness?",
				illnessList: {
					title: "Serious illnesses include:",
					diseases: [
						"Cancer",
						"Chronic neurological diseases",
						"Chronic Obstructive Pulmonary Disease (COPD) and End-stage Lung Disease",
						"Dementia",
						"End-Stage Renal Failure (ESRF)",
						"Heart Failure",
						"Severe Liver Disease",
					],
				},
				Yes: {
					alertType: "error",
					description: [
						"As you have an existing serious illness, we recommend discussing your options with your current healthcare team or an ACP facilitator rather than using this service.",
					],
				},
				No: {
					alertType: "",
				},
				NotSure: {
					alertType: "error",
					description: [
						"If you are unsure of your health condition, we recommend discussing your options with your current healthcare team or an ACP facilitator rather than using this service.",
					],
				},
			},
			{
				title: "Do you have any existing chronic condition?",
				illnessList: {
					title: "Examples of chronic conditions include: ",
					diseases: [
						"Chronic Kidney Disease",
						"Diabetes",
						"Ischemic Heart Disease (IHD)",
						"Parkinson's Disease",
						"Stroke",
					],
					info: "This is not an exhaustive list of all chronic conditions. You can have other chronic conditions beyond this list. Please speak to your regular doctor if in doubt.",
				},
				Yes: {
					alertType: "",
					description: [""],
				},
				No: {
					alertType: "description",
					description: ["By proceeding with this service, you are confirming that:"],
					confirmingList: [
						"you do not have any serious illness",
						"you do not have any chronic condition",
						"you are responsible for answering your health declarations correctly",
					],
				},
				NotSure: {
					alertType: "error",
					description: [
						"If you are unsure of your health condition, we recommend discussing your options with your current healthcare team or an ACP facilitator rather than using this service.",
					],
				},
			},
			{
				title: "Were you referred by your regular doctor to use this service?",
				description:
					"A regular doctor could either be the primary care physician or specialist whom you visit regularly.",
				Yes: {
					alertType: "description",
					description: ["By proceeding with this service, you are confirming that:"],
					confirmingList: [
						"you do not have any serious illness",
						"you were referred by a healthcare professional to use this service",
						"you are responsible for answering your health declarations correctly",
					],
				},
				No: {
					alertType: "error",
					description: [
						"As you have an existing chronic condition, we recommend discussing your options with your current healthcare team or an ACP facilitator rather than using this service.",
					],
				},
			},
		],
	},
};

export const AcpNextStepURL: { [key in AcpFormStep]: string } = {
	[AcpFormStep.PERSONAL_DETAILS]: AcpUrl.healthConcerns,
	[AcpFormStep.HEALTH_CONCERN]: AcpUrl.personalExperience,
	[AcpFormStep.PERSONAL_EXPERIENCES]: AcpUrl.thingsYouEnjoy,
	[AcpFormStep.THINGS_YOU_ENJOY]: AcpUrl.sourcesOfSupport,
	[AcpFormStep.SOURCES_OF_SUPPORT]: AcpUrl.fears,
	[AcpFormStep.FEARS]: AcpUrl.goalsOfCare,
	[AcpFormStep.GOALS_OF_CARE]: AcpUrl.carePreferences,
	[AcpFormStep.CARE_PREFERENCES]: AcpUrl.nhs,
	[AcpFormStep.NHS]: AcpUrl.nhsAdditionalRequest,
	[AcpFormStep.NHS_ADDITIONAL_REQUESTS]: AcpUrl.review,
	[AcpFormStep.REVIEW]: AcpUrl.nextSteps,
};

export const AcpPreviousStepURL: { [key in AcpFormStep]: string } = {
	// no previous step for personal_details, this is to hide the back button on step 1
	[AcpFormStep.PERSONAL_DETAILS]: "",
	[AcpFormStep.HEALTH_CONCERN]: AcpUrl.personalDetails,
	[AcpFormStep.PERSONAL_EXPERIENCES]: AcpUrl.healthConcerns,
	[AcpFormStep.THINGS_YOU_ENJOY]: AcpUrl.personalExperience,
	[AcpFormStep.SOURCES_OF_SUPPORT]: AcpUrl.thingsYouEnjoy,
	[AcpFormStep.FEARS]: AcpUrl.sourcesOfSupport,
	[AcpFormStep.GOALS_OF_CARE]: AcpUrl.fears,
	[AcpFormStep.CARE_PREFERENCES]: AcpUrl.goalsOfCare,
	[AcpFormStep.NHS]: AcpUrl.carePreferences,
	[AcpFormStep.NHS_ADDITIONAL_REQUESTS]: AcpUrl.nhs,
	[AcpFormStep.REVIEW]: AcpUrl.nhsAdditionalRequest,
};

export const AcpProgressIndicatorStep: { [key in AcpFormStep]: number } = {
	[AcpFormStep.PERSONAL_DETAILS]: 0,
	[AcpFormStep.HEALTH_CONCERN]: 1,
	[AcpFormStep.PERSONAL_EXPERIENCES]: 1,
	[AcpFormStep.THINGS_YOU_ENJOY]: 2,
	[AcpFormStep.SOURCES_OF_SUPPORT]: 2,
	[AcpFormStep.FEARS]: 3,
	[AcpFormStep.GOALS_OF_CARE]: 4,
	[AcpFormStep.CARE_PREFERENCES]: 5,
	[AcpFormStep.NHS]: 6,
	[AcpFormStep.NHS_ADDITIONAL_REQUESTS]: 6,
	[AcpFormStep.REVIEW]: 7,
};

export const AcpReviewStep: { [key in AcpFormStep]: number } = {
	[AcpFormStep.PERSONAL_DETAILS]: 0,
	[AcpFormStep.HEALTH_CONCERN]: 1,
	[AcpFormStep.PERSONAL_EXPERIENCES]: 2,
	[AcpFormStep.THINGS_YOU_ENJOY]: 3,
	[AcpFormStep.SOURCES_OF_SUPPORT]: 4,
	[AcpFormStep.FEARS]: 5,
	[AcpFormStep.GOALS_OF_CARE]: 6,
	[AcpFormStep.CARE_PREFERENCES]: 7,
	[AcpFormStep.NHS]: 8,
	[AcpFormStep.NHS_ADDITIONAL_REQUESTS]: 9,
	[AcpFormStep.REVIEW]: 10,
};

export enum AcpStepStatus {
	COMPLETED = "Completed",
	IN_PROGRESS = "In progress",
	NOT_STARTED = "Not started",
	REGISTERED = "Registered",
	NOT_SHARED = "Not shared",
}

export const VIEW_ACP_NEXT_STEPS_SESSION_STORAGE = "VIEW_ACP_NEXT_STEPS_SESSION_STORAGE";

import { EOLErrorCodes } from "app/common/errors";
import ResponseError from "app/common/responseError";
import LanguageSelector from "app/components/basic/LanguageSelector";
import { useGlobalLoadingState } from "app/components/basic/LinearProgressIndicator/GlobalLoadingStateProvider";
import UnexpectedError from "app/components/composites/ErrorBoundary/ErrorPages";
import CustomErrorPage from "app/components/composites/ErrorBoundary/ErrorPages/CustomError";
import { setLogout } from "app/utils/url";
import { useRouter } from "next/router";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

export interface CustomError {
	title: string;
	description: string | JSX.Element;
	type?: number;
	actionButton?: {
		label: string;
		redirectPath?: string;
	};
}

interface IErrorHandler {
	sendError: (type: ResponseError, translation?: boolean, customError?: CustomError) => void;
}

const ErrorHandler = createContext<IErrorHandler>({
	sendError: () => {},
});

export const useErrorHandler = (): IErrorHandler => useContext(ErrorHandler);

export const ErrorHandlerProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	const [error, setError] = useState<ResponseError | null>(null);
	const [errorLocation, setErrorLocation] = useState<string | null>(null);
	const [showTranslation, setShowTranslation] = useState(true);
	const [customError, setCustomError] = useState<CustomError | undefined>();
	const [, setIsLoading] = useGlobalLoadingState();
	const router = useRouter();

	const sendError = useCallback(
		(type: ResponseError, translation = true, customError?: CustomError): void => {
			setErrorLocation(router.asPath);
			setError(type);
			setShowTranslation(translation);
			setCustomError(customError);
		},
		[router],
	);

	useEffect(() => {
		if (errorLocation && router.asPath !== errorLocation) {
			setError(null);
			setErrorLocation(null);
		}
	}, [errorLocation, router]);

	useEffect(() => {
		setIsLoading(false);

		if (error?.name === EOLErrorCodes.UserNotFoundError) {
			setLogout();
		}
	}, [error, setIsLoading]);

	return (
		<ErrorHandler.Provider value={{ sendError }}>
			{error ? (
				<>
					{showTranslation && <LanguageSelector className="mt40" id="language-selector-top" error={true} />}
					{customError?.title ? <CustomErrorPage errorData={customError} /> : <UnexpectedError />}
					{showTranslation && (
						<LanguageSelector
							className="mb104"
							id="language-selector-bottom"
							error={true}
							hideWhenTranslationUnavailable
						/>
					)}
				</>
			) : (
				children
			)}
		</ErrorHandler.Provider>
	);
};

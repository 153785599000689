import { IsString } from "class-validator";
import { HTTP_VERBS } from "../../enums";
import { AcpNhsDO } from "../domainObjects/v3/acpNhs";
import { AcpNhsStatus } from "../enums";

export const httpVerb = HTTP_VERBS.POST;
export const url = "/api/v3/acp/nhs";

export class Body {
	@IsString()
	id!: string;

	@IsString()
	email!: string;

	@IsString()
	phoneNumber!: string;

	status!: AcpNhsStatus;
}
export class Response extends AcpNhsDO {}

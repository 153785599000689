export enum BestInterests {
	TalkToLovedOnes = "TalkToLovedOnes",
	BeIndependent = "BeIndependent",
	EnjoyFavouriteFood = "EnjoyFavouriteFood",
	LearnAndGrowSkillsAndKnowledge = "LearnAndGrowSkillsAndKnowledge",
	BeDevoutFollowerOfFaith = "BeDevoutFollowerOfFaith",
	Others = "Others",
}

export enum BestInterestsLabel {
	TalkToLovedOnes = "Being able to talk to my loved ones",
	BeIndependent = "Being independent - moving around and going places by myself",
	EnjoyFavouriteFood = "Being able to enjoy my favourite food",
	LearnAndGrowSkillsAndKnowledge = "Being able to learn and grow my skills and knowledge",
	BeDevoutFollowerOfFaith = "Being a devout follower of my faith",
	Others = "Others",
}

// to update to nhs and non-nhs submission later
export enum AcpFormV3Status {
	DRAFT = "DRAFT",
	SUBMITTED = "SUBMITTED",
	EXPIRED = "EXPIRED",
	PENDING_NHS_APPROVAL = "PENDING_NHS_APPROVAL",
	// interim status that may be required later if submission fail on the first try
	PENDING_SUBMISSION_TO_ACP = "PENDING_SUBMISSION_TO_ACP",
	SUBMITTED_TO_ACP_WITH_NHS = "SUBMITTED_TO_ACP_WITH_NHS",
}
export enum AcpNhsStatus {
	PENDING = "PENDING",
	DECLINED = "DECLINED",
	UNDERAGE = "UNDERAGE",
	ACCEPTED = "ACCEPTED",
}

export enum AcpVersion {
	"ACP_V3" = "ACP_V3",
	"ACP_V3_1" = "ACP_V3_1",
	"ACP_PDF" = "ACP_PDF",
	"ACP_PDF_V3_1" = "ACP_PDF_V3_1",
}

export enum AcpTaskStatus {
	PENDING = "PENDING",
	FAILED = "FAILED",
	COMPLETED = "COMPLETED",
}

export enum AcpTaskType {
	NO_NHS_ACP_EMAIL = "NO_NHS_ACP_EMAIL",
	NHS_ASSIGNED_EMAIL = "NHS_ASSIGNED_EMAIL",
	SUBMISSION_TO_ACP = "SUBMISSION_TO_ACP",
	NHS_REJECTED_EMAIL = "NHS_REJECTED_EMAIL",
	NHS_UNDERAGE_EMAIL = "NHS_UNDERAGE_EMAIL",
	SUBMISSION_TO_ACP_NHS_EMAIL = "SUBMISSION_TO_ACP_NHS_EMAIL",
	SUBMISSION_TO_ACP_APPLICANT_EMAIL = "SUBMISSION_TO_ACP_APPLICANT_EMAIL",
}

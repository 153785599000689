/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IsACPContact } from "app/common/validators/acpContactValidator";
import { Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { DraftContentDto, DraftContentDtoV2, PdfDto, PdfDtoV2 } from "./acpDraft";

export class AcpSaveDraftDto {
	@ValidateNested({ each: true, always: true })
	@Type(() => DraftContentDto)
	@IsACPContact()
	draftContent!: DraftContentDto;
}
export class AcpSaveDraftDtoV2 {
	@ValidateNested({ each: true, always: true })
	@Type(() => DraftContentDtoV2)
	@IsACPContact()
	draftContent!: DraftContentDtoV2;
}

export class AcpPdfDto {
	@ValidateNested({ each: true, always: true })
	@Type(() => PdfDto)
	@IsACPContact()
	data!: PdfDto;
}
export class AcpPdfDtoV2 {
	@ValidateNested({ each: true, always: true })
	@Type(() => PdfDtoV2)
	@IsACPContact()
	data!: PdfDtoV2;
}

import { ZonedDateTime } from "@js-joda/core";

import { produce } from "immer";
import { useRouter } from "next/router";
import { createContext, useEffect, useReducer } from "react";
import { AcpUrl, VIEW_ACP_NEXT_STEPS_SESSION_STORAGE } from "../../constants";
import {
	AcpAction,
	AcpActionType,
	IAcpContextType,
	IAcpState,
	ICarePreferences,
	IFears,
	IGoalsOfCare,
	IHealthConcerns,
	INHS,
	INHSAdditionalRequests,
	IPersonalDetails,
	IPersonalExperiences,
	ISourcesOfSupport,
	IThingsYouEnjoyInLife,
} from "./type";

// =============================================================================
// DEFAULT VALUES
// =============================================================================
export const initialAcpForm = {
	draftId: "",
	currentStep: 0,
	latestStep: 0,
	personalDetails: undefined,
	healthConcerns: undefined,
	personalExperiences: undefined,
	thingsYouEnjoyInLife: undefined,
	sourcesOfSupport: undefined,
	carePreferences: undefined,
	goalsOfCare: undefined,
	activities: undefined,
	fears: undefined,
	nhs: undefined,
	nhsAdditionalRequests: undefined,
	suitabilityCheck: false,
	submittedForm: undefined,
};

export const initialValues: IAcpState = {
	isRenderingForm: true,
	isLoading: false,
	draftSaving: true,
	showDevMenu: false,
	draftLastSavedTime: undefined,
	lastSaveDraftSuccess: false,
	...initialAcpForm,
};

// =============================================================================
// REDUCER
// =============================================================================
export const acpReducer = (state: IAcpState, action: AcpAction): IAcpState =>
	produce(state, (draft) => {
		switch (action.type) {
			case AcpActionType.ENABLE_DRAFT_SAVE:
				draft.draftSaving = action.payload;
				return;
			case AcpActionType.RESET_FORM:
				return {
					...initialValues,
					draftSaving: draft.draftSaving,
					isRenderingForm: false,
				};
			case AcpActionType.SET_LOADING_STATE:
				draft.isLoading = action.payload;
				return;
			case AcpActionType.SET_RENDERING_FORM:
				draft.isRenderingForm = action.payload;
				return;
			case AcpActionType.SHOW_DEV_MENU:
				draft.showDevMenu = action.payload;
				return;
			case AcpActionType.SET_PERSONAL_DETAILS:
				draft.personalDetails = action.payload;
				draft.currentStep = 0;
				return;
			case AcpActionType.SET_HEALTH_CONCERNS:
				draft.healthConcerns = action.payload;
				draft.currentStep = 1;
				return;
			case AcpActionType.SET_PERSONAL_EXPERIENCES:
				draft.personalExperiences = action.payload;
				draft.currentStep = 2;
				return;
			case AcpActionType.SET_THINGS_YOU_ENJOY_IN_LIFE:
				draft.thingsYouEnjoyInLife = action.payload;
				draft.currentStep = 3;
				return;
			case AcpActionType.SET_SOURCES_OF_SUPPORT:
				draft.sourcesOfSupport = action.payload;
				draft.currentStep = 4;
				return;
			case AcpActionType.SET_FEARS:
				draft.fears = action.payload;
				draft.currentStep = 5;
				return;
			case AcpActionType.SET_GOALS_OF_CARE:
				draft.goalsOfCare = action.payload;
				draft.currentStep = 6;
				return;
			case AcpActionType.SET_CARE_PREFERENCES:
				draft.carePreferences = action.payload;
				draft.currentStep = 7;
				return;
			case AcpActionType.SET_NHS:
				draft.nhs = action.payload;
				draft.currentStep = 8;
				return;
			case AcpActionType.SET_NHS_ADDITIONAL_REQUESTS:
				draft.nhsAdditionalRequests = action.payload;
				draft.currentStep = 9;
				return;
			case AcpActionType.SET_REVIEW:
				draft.currentStep = 10;
				return;
			case AcpActionType.SET_ACTIVITIES:
				draft.activities = action.payload;
				return;
			case AcpActionType.SET_LATEST_STEP:
				draft.latestStep = action.payload;
				return;
			case AcpActionType.SET_FORM_DATA:
				return action.payload;
			case AcpActionType.HAS_SAVE_DRAFT_DATA:
				draft.lastSaveDraftSuccess = action.payload;
				if (action.payload) {
					draft.draftLastSavedTime = ZonedDateTime.now();
				}
				return;
			case AcpActionType.SET_SUBMITTED_FORM: {
				draft.submittedForm = action.payload;
				draft.latestStep = action.payload.latestStep;
				draft.personalDetails = action.payload.formContext?.personalDetails as IPersonalDetails;
				draft.healthConcerns = action.payload.formContext?.healthConcerns as IHealthConcerns;
				draft.thingsYouEnjoyInLife = action.payload.formContext?.thingsYouEnjoyInLife as IThingsYouEnjoyInLife;
				draft.nhsAdditionalRequests = action.payload.formContext
					?.nhsAdditionalRequests as INHSAdditionalRequests;
				draft.personalExperiences = action.payload.formContext?.personalExperiences as IPersonalExperiences;
				draft.goalsOfCare = action.payload.formContext?.goalsOfCare as IGoalsOfCare;
				draft.carePreferences = action.payload.formContext?.carePreferences as ICarePreferences;
				draft.sourcesOfSupport = action.payload.formContext?.sourcesOfSupport as ISourcesOfSupport;
				draft.fears = action.payload.formContext?.fears as IFears;
				draft.nhs = action.payload.formContext?.nhs as INHS;
				return;
			}
			case AcpActionType.SET_DRAFT_DATA: {
				const {
					draftId,
					currentStep,
					latestStep,
					nhsAdditionalRequests,
					thingsYouEnjoyInLife,
					personalDetails,
					goalsOfCare,
					carePreferences,
					nhs,
					healthConcerns,
					personalExperiences,
					fears,
					sourcesOfSupport,
				} = action.payload;

				draft.draftId = draftId;
				draft.currentStep = currentStep;
				draft.latestStep = latestStep;
				draft.healthConcerns = healthConcerns;
				draft.thingsYouEnjoyInLife = thingsYouEnjoyInLife;
				draft.nhsAdditionalRequests = nhsAdditionalRequests;
				draft.personalExperiences = personalExperiences;
				draft.personalDetails = personalDetails;
				draft.goalsOfCare = goalsOfCare;
				draft.carePreferences = carePreferences;
				draft.sourcesOfSupport = sourcesOfSupport;
				draft.nhs = nhs;
				draft.isRenderingForm = false;
				draft.fears = fears;
				return;
			}
			default:
				return draft;
		}
	});

// =============================================================================
// CONTEXT
// =============================================================================
export const AcpContext = createContext<IAcpContextType>({
	state: initialValues,
	dispatch: () => null,
});

// =============================================================================
// CONTEXT PROVIDER
// =============================================================================
export const AcpProvider = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
	const [state, dispatch] = useReducer(acpReducer, initialValues);
	const router = useRouter();

	useEffect(() => {
		if (router.pathname !== AcpUrl.nextSteps) {
			sessionStorage.removeItem(VIEW_ACP_NEXT_STEPS_SESSION_STORAGE);
		}
	}, []);

	return <AcpContext.Provider value={{ state, dispatch }}>{children}</AcpContext.Provider>;
};

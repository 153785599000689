import { IsString } from "class-validator";
import { AcpNhsStatus } from "../../enums";

export class AcpNhsDO {
	@IsString()
	phone!: string;

	@IsString()
	email!: string;

	status!: AcpNhsStatus;
}
